<template>
  <div class="static-page">
    <b-overlay
      :show="page === undefined"
      z-index="0"
    >
      <page-view
        v-if="page"
        :page="page" slug="static"
        @close="$router.push({ name: 'home' })"
      />
    </b-overlay>
  </div>
</template>

<script>
import { PageView } from '@/components/layouts'


export default {
  name: 'Page',

  props: {
    id: { type: [String, Number], required: true }
  },

  components: {
    PageView
  },

  data () {
    return {
      page: undefined
    }
  },

  async created () {
    this.page = await this.$store.dispatch('QUERY_PAGE_BY_ID', Number(this.id))
  }
}
</script>

<style lang="scss" scoped>
.static-page {
  min-height: 100%;

  .b-overlay-wrap {
    position: static !important;
  }
}
</style>
